
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { IndexComponent } from '../index/index.component';
import { AuthEGuard } from 'src/app/core/guard/authE.guard';
import { ControlAsistComponent } from '../tramites-y-servicios/control-asist/control-asist.component';
import { ListaAsistComponent } from '../tramites-y-servicios/lista-asist/lista-asist.component';
import { MapaComponent } from '../mapa/mapa.component';
import { ListadoComponent } from '../conferencia/listado/listado.component';
import { ConferenciaComponent } from '../conferencia/conferencia.component';
import { ConstComponent } from '../conferencia/constancias/constancias.component';
import { ListaElecComponent } from '../conferencia/lista-elec/lista-elec.component';
import { ReimprimirComponent } from '../conferencia/reimprimir/reimprimir.component';

const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/

  {
    path: 'electoral/busqueda',
    component: ConferenciaComponent,

  }, {
    path: 'electoral/nombramiento',
    component: ConstComponent,

  }, {
    path: 'electoral/lista',
    component: ListaElecComponent,

  }, {
    path: 'electoral/reimpresion/:id',
    component: ReimprimirComponent,

  }
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }

