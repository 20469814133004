import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { timer } from 'rxjs';
@Component({
  selector: 'app-conferencia',
  templateUrl: './conferencia.component.html',
  styleUrls: ['./conferencia.component.scss']
})
export class ConferenciaComponent {
  boton: any;
  id: any;
  data: any;
  areas: any;
  area: any;
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];
animation=true

  nuevoPC = new FormGroup({
    NOMBRE: new FormControl('', Validators.required),
    PUESTO: new FormControl('', Validators.required),
    CORREO: new FormControl('', Validators.required),
    TELEFONO: new FormControl('', Validators.required),

  });

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {
    // this.oberserableTimer()

  }

  // oberserableTimer() {
  //   const source = timer(1000, 2000);
  //   const abc = source.subscribe(val => {
  //     this.animation = true;
  //     this.cambiarFlag()
  //   });
  // }

  flag = false;

  cambiarFlag() {
    this.flag = !this.flag;
  }

  postForm(form: any) {




    this.alerts.alertaConfirmacionAgregar('Registro de datos', '¿Desea enviar los datos de su registro?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (form.NOMBRE !== '' &&
            form.PUESTO !== '' &&
            form.TELEFONO !== '' &&
            form.CORREO !== '') {
 console.log(form);
            this.api.electoral(form).subscribe(data => {


              this.alerts.realizado('Completado', 'Se ha enviado el registro con exito').then((res: any) => {
                localStorage.setItem('nombre',form.NOMBRE)
                localStorage.setItem('cargo',form.PUESTO)
                this.router.navigate(['admin/electoral/nombramiento'])
              })

            }, error => {

              this.alerts.alertaError('Ups', 'Error de registro')
            })

          } else {
            this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
          }

        }

      })

  }



  verRegistros(){
    this.router.navigate(['admin/electoral/lista'])
  }

}
