<section style="background-color:  #F3F3F4; height: 6vh; width: 100%;"></section>

<div class="wrapper">
  <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">
        <div class="row">

          <div style="justify-content: right; display: flex; width: 100%;" class="col-sm-10">
            <select name="filtro" id="filtro" #cbCampo
              style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
              <option selected value="NOMBRE">NOMBRE</option>
              <option value="PUESTO">CARGO</option>


            </select>
            <input type="text" style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px;"
              placeholder="Ingresa tu búsqueda" (keyup)="buscar()" #ctCadena>
            <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
              <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscando === false"></i>
              <div class="d-flex justify-content-center" *ngIf="this.buscando === true">
                <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
              </div>
            </button>
          </div>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #000000;">

            <th scope="col">NOMBRE</th>
            <th scope="col">CARGO</th>
            <th scope="col">TELEFONO</th>
            <th scope="col">CORREO</th>

          </thead>
          <tbody>
            <tr class="tr"
              *ngFor="let dato of activos | paginate: { itemsPerPage: 10, currentPage: pages, id:'activos' }; let i = index">

              <td> {{dato.NOMBRE}}</td>
              <td> {{dato.PUESTO}}</td>
              <td> {{dato.TELEFONO}} </td>
              <td>  {{dato.CORREO}}</td>

              <td><button id="botonazo" style="border-radius: 1vh; background-color: #d40202; color: #F3F3F4; border: solid;border-color: #d40202;box-shadow:-5px 6px 20px 0px rgba(176, 0, 0, 0.753) ;"
                (click)="verdato(dato.ID)"> Ver Registro </button> </td>

                <td><button id="botonazo" style="border-radius: 1vh; background-color: #d40202; color: #F3F3F4; border: solid;border-color: #d40202;box-shadow:-5px 6px 20px 0px rgba(176, 0, 0, 0.753) ;"
                  (click)="eliminar(dato.ID)"> Eliminar </button> </td>


            </tr>
            <tr *ngIf="this.noEncontrado === true">
              <td style="justify-content: center; text-align: center;" colspan="6"><strong>No se encontro ningun
                  registro con ese dato</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="6">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #000000;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>

    </div>

  </section>
</div>


<!--Imprimir Reportes-->
<div hidden>
  <table class="table table-bordered" id="reporte">
    <tbody class="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">NOMBRE</th>
        <th scope="col">PROPIETARIO</th>
        <th scope="col">CELULAR</th>
         <th scope="col">GIRO</th>
        <th scope="col">DOMICILIO</th>
        <th scope="col">COLONIA</th>
        <th scope="col">ENTRE CALLES</th>
        <th scope="col">POBLACION FIJA</th>
        <th scope="col">POBLACION FLOTANTE</th>
        <th scope="col">HORARIO</th>
        <th scope="col">NUMERO</th>
        <th scope="col">FOLIO</th>
        <th scope="col">RECIBO</th>
        <th scope="col">MONTO</th>
        <th scope="col">SEGURIDAD</th>
        <th scope="col">RIESGO</th>
        <th scope="col">VIABILIDAD</th>
        <th scope="col">FECHA</th>
        <th scope="col">SOLICITANTE</th>

      </tr>
      <tr *ngFor="let dato of activos; let i = index">
        <td> {{dato.ID}}</td>
        <td> {{dato.NOMBRE}}</td>
        <td> {{dato.PROPIETARIO}} </td>
        <td>  {{dato.CELULAR}}</td>
        <td>  {{dato.GIRO}}</td>
        <td>  {{dato.DOMICILIO}}</td>
        <td>  {{dato.COLONIA}}</td>
        <td>  {{dato.ENT_CALLES}} </td>
        <td>  {{dato.P_FIJA}} </td>
        <td>  {{dato.P_FLOTANTE}} </td>
        <td>  {{dato.HORARIO}} </td>
        <td>  {{dato.NUMERO}} </td>
        <td>  {{dato.FOLIO}} </td>
        <td>  {{dato.RECIBO}} </td>
        <td>  {{dato.MONTO}} </td>
        <td> {{dato.SEGURIDAD}} </td>
        <td>  {{dato.RIESGO}} </td>
        <td>  {{dato.VIABILIDAD}} </td>
        <td> {{dato.FECHA}} </td>
        <td> {{dato.SOLICITANTE}} </td>


      </tr>
    </tbody>
  </table>
</div>

<!-- Inicio Modal Tabla Comaparativa -->
<dialog id="modalTabla" style="border: none; border-radius: 1vh; width: 60%;" class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalTabla()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Motivos de Registro</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

    <div class="col-2"></div>
    <div class="col-8">
      <ul *ngIf="this.cargando1 === false">
        <li *ngFor="let dato of porId">{{dato.MOTIVO}}</li>
      </ul>

      <div class="d-flex justify-content-center"  *ngIf="this.cargando1 === true">
        <div class="spinner-border" style="color: #000000;"></div>
      </div>

    </div>
    <div class="col-2"></div>
  </div>
</dialog>
<!-- Final Modal Tabla Comaparativa -->
