import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';
@Component({
  selector: 'app-lista-elec',
  templateUrl: './lista-elec.component.html',
  styleUrls: ['./lista-elec.component.scss']
})
export class ListaElecComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  chart: any;
  yValues = [0, 0];
  label: any;
  canvas: any;
  imagen: any;
  spinner: any

  pages: number = 1;
  activos: any;
  inactivos: any;

  sector: any;
  nombre: any;
  sectores: any = [];
  zonas: any = [];

  sectorIn: any;
  nombreIn: any;
  sectoresIn: any = [];
  zonasIn: any = [];

  noEncontrado: any;
  noEncontradoIn: any;

  //CargaDeContenido
  cargando: any;
  cargando1: any;
  cargandoIn: any;
  objetivo: any
  listaObjetivos: any;
  n: any;
  dominio: any;
  listaIndicadores: any
  indicador: any
  idIndicador: any
  valor: any
  boton: any
  tabla: any;
  tablaValor: any
  //Busqueda
  buscando: any;
  buscandoIn: any;

  porId: any;

  name = 'Registros.xlsx';

  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    PUESTO: undefined,
    TELEFONO: undefined,
    CORREO: undefined,

  };


  constructor(private router: Router, private general: GeneralService,private alerts: SweetAlertService) { }

  ngOnInit() {

    this.buscando = false;
    this.noEncontrado = false;
    this.cargando = true;
    this.cargando1 = true;

    this.general.listaElectoral().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }

  cambio() {
    this.pages = 1;
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;
      this.general.buscarElectoral(columName, value).subscribe((res: any) => {
        this.activos = res.body
        this.pages = 1;
        if (this.activos.length === 0) {
          this.noEncontrado = true;
          this.buscando = false;
        } else {
          this.noEncontrado = false;
          this.buscando = false;
        }
      });
    } else {
      this.general.listaElectoral().subscribe(res => {
        this.activos = res.body
        this.pages = 1;
        this.noEncontrado = false;
        this.buscando = false;
        console.log(this.activos);
      })
    }
  }

  id(id: any) {
    this.router.navigate(['admin/proteccion-civil/servicios/', id])
  }

  descargarDatos() {
    this.exportToExcel();
  }

  generarTablaDatos() {

    this.general.buscarServiciosPC('DOMINIO', this.tablaValor).subscribe(res => {
      this.tabla = res.body;
      console.log(this.tabla);
    })
  }

  exportToExcel(): void {
    let element = document.getElementById('reporte');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.name);
  }

  reporte() {
    this.exportToExcel();
  }


  cerrarModalTabla() {
    let dialog = document.getElementById("modalTabla") as HTMLDialogElement;
    dialog.close();
  }

verdato(id:any){
this.router.navigate(['admin/electoral/reimpresion/'+ id])
}


eliminar(ID: any) {

  this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        this.eliminarForm.ID = ID;
        this.eliminarForm.ESTATUS = 1;
        this.general.eliminarElectoral(ID).subscribe(
          (data: any) => {


            console.log(ID);
            this.alerts.alertaRealizado().then((res: any) => {
location.reload()
            })
          })

      }

    })


}

}
