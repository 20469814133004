import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-reimprimir',
  templateUrl: './reimprimir.component.html',
  styleUrls: ['./reimprimir.component.scss']
})
export class ReimprimirComponent {
  nombre:any
  activos:any
  documento: any;
  token: any;
  var: any
  id:any
  headers = ['NOMBRE','RAZON_SOCIAL',' CELULAR','CORREO','DOMICILIO','LOCALIDAD','N_PARTICIPANTES'];
  pc:any
  constructor(private api: GeneralService, private router: Router, private alertas: SweetAlertService,private activerouter: ActivatedRoute) { }

  nuevoPC = new FormGroup({
    NOMBRE: new FormControl('', Validators.required),

  });


  ngOnInit() {
    this.id = this.activerouter.snapshot.paramMap.get('id');
    this.api.ElectoralID(this.id).subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
 })
  }

  buscar(): void {




      this.api.buscarElectoral('NOMBRE',this.activos[0].NOMBRE).subscribe((res: any) => {
this.crearPDF()

      });





  }





  crearPDF() {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [8.5, 11]

    });





    doc.setFontSize(30);



    var logo = new Image();
    logo.src = 'assets/img/cons.png';
    doc.addImage(logo, 'JPEG', 0, 0, 8.5, 11);
doc.text(this.activos[0].NOMBRE , 4.2, 3.8, {align:"center"});
{
  doc.setFontSize(20);
  doc.text(this.activos[0].PUESTO , 4.2, 4.75, {align:"center"});
}

doc.save(this.activos[0].NOMBRE + '.pdf')



  }



}
