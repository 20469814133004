import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-constancias',
  templateUrl: './constancias.component.html',
  styleUrls: ['./constancias.component.scss']
})
export class ConstComponent {
nombre:any
  documento: any;
  token: any;
  var: any
  headers = ['NOMBRE','RAZON_SOCIAL',' CELULAR','CORREO','DOMICILIO','LOCALIDAD','N_PARTICIPANTES'];
  pc:any
  puesto:any
  activos:any
  constructor(private api: GeneralService, private router: Router, private alertas: SweetAlertService) { }

  nuevoPC = new FormGroup({
    NOMBRE: new FormControl('', Validators.required),

  });


  ngOnInit() {
this.nombre=localStorage.getItem('nombre')

this.puesto=localStorage.getItem('puesto')

  }

  buscar(): void {




      this.api.buscarElectoral('NOMBRE',this.nombre).subscribe((res: any) => {
        this.activos = res.body
        console.log(this.activos);

this.crearPDF()

      });





  }





  crearPDF() {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [8.5, 11]

    });





    doc.setFontSize(30);



    var logo = new Image();
    logo.src = 'assets/img/cons.png';
    doc.addImage(logo, 'JPEG', 0, 0, 8.5, 11);
doc.text(this.activos[0].NOMBRE , 4.2, 3.8, {align:"center"});
{
  doc.setFontSize(20);
  doc.text(this.activos[0].PUESTO , 4.2, 4.75, {align:"center"});
}

doc.save(this.activos[0].NOMBRE + '.pdf')



  }



}
